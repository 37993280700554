<template>
  <router-view v-if="apiState === 3"></router-view>
  <loader v-else></loader>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import enums from '@main/enums';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  computed: {
    ...mapState('pr_agency', ['apiState', 'services']),
  },
  methods: {
    ...mapActions('pr_agency', ['loadServices']),
    ...mapMutations('pr_agency', ['setApiState']),
  },
  async mounted() {
    this.setApiState(enums.LOADING);
    try {
      await this.loadServices();
    } catch (e) {
      this.setApiState(enums.ERROR);
      return;
    }
    this.setApiState(enums.LOADED);
  },
};
</script>

<style lang="scss" scoped></style>
